const { useMemo } = require("react");
const { ADMIN_LEVEL_ACCESS } = require("resources/constants/constData");

export const filteredAdmin = (role) => {
  return (
    ADMIN_LEVEL_ACCESS.filter((i) => {
      return role === i;
    }).length > 0
  );
};

export const mapStudentData = (data, replacedData) => {
  const learners = data.map((item2) => {
    const item1 = replacedData.find((item) => item.email_id === item2.email_id);

    if (!item1) return item2;

    const mergedLearningPath = item2.learning_path.phase_list.map((phase2) => {
      const phase1 = item1.learning_path.phase_list.find(
        (p1) => p1.phase_name === phase2.phase_name
      );
      if (!phase1) return phase2;

      const mergedMacroCertifications = phase2.macro_certification_list.map(
        (macroCert2) => {
          const macroCert1 = phase1.macro_certification_list.find(
            (mc1) =>
              mc1.macro_certification_name ===
              macroCert2.macro_certification_name
          );

          if (!macroCert1) return macroCert2;

          const mergedCourses = macroCert2.course_list.map((course2) => {
            const course1 = macroCert1.course_list.find(
              (c1) => c1.course_name === course2.course_name
            );
            if (!course1) return course2;

            const mergedLessons = course2.lesson_list?.map((lesson2) => {
              const lesson1 = course1.lesson_list?.find(
                (l1) => l1.lesson_name === lesson2.lesson_name
              );

              return lesson1
                ? {
                    ...lesson1,
                    lesson_skill_points_actual:
                      lesson2.lesson_skill_points_actual,
                    lesson_skill_points_total:
                      lesson2.lesson_skill_points_total,
                  }
                : lesson2;
            });

            // Merge the course progress or any other data from course1 (replacedData) into course2 (data)
            return {
              ...course2,
              course_skill_points_actual:
                course1.course_skill_points_actual ??
                course2.course_skill_points_actual,
              course_skill_points_total:
                course1.course_skill_points_total ??
                course2.course_skill_points_total,
              course_progress: course1.course_progress, // Example field merge
              lesson_list: mergedLessons,
            };
          });

          return {
            ...macroCert2,
            course_list: mergedCourses,
          };
        }
      );

      return {
        ...phase2,
        macro_certification_list: mergedMacroCertifications,
      };
    });

    return {
      ...item2,
      learner_progress_bar: item1.learner_progress_bar,
      learning_path: {
        ...item2.learning_path,
        phase_list: mergedLearningPath,
      },
    };
  });

  return learners;
};

export const getPathAndScore = (course_name, arr, track) => {
  const finalResult = arr?.flatMap((item) =>
    item?.course
      ?.filter((items) => items.course_name === course_name)
      .flatMap((student) =>
        student?.student_data
          ?.filter((stg) => stg?.student_email === track.email_id)
          .flatMap((data) =>
            data?.assignment_submitted_detail?.flatMap(({ path, score }) => ({
              path,
              score,
            }))
          )
      )
  );

  const finalQuizResult = arr?.flatMap((item) =>
    item?.course
      ?.filter((items) => items.course_name === course_name)
      .flatMap((student) =>
        student?.student_data
          ?.filter((stg) => stg?.student_email === track.email_id)
          .flatMap((lesson) =>
            lesson?.lessons?.flatMap((room) =>
              room?.room?.flatMap((quiz) =>
                quiz?.quiz?.flatMap(({ quiz_name, quiz_score }) => ({
                  name: quiz_name,
                  score: quiz_score,
                }))
              )
            )
          )
      )
  );

  const finalKahootResult = arr?.flatMap((item) =>
    item?.course
      ?.filter((items) => items.course_name === course_name)
      .flatMap((student) =>
        student?.student_data
          ?.filter((stg) => stg?.student_email === track.email_id)
          .flatMap((lesson) =>
            lesson?.lessons?.flatMap((room) =>
              room?.room
                ?.filter((room_name) => room_name.kahoot_game_name != "")
                ?.flatMap(({ kahoot_game_name }) => ({
                  name: kahoot_game_name,
                  score: 0,
                }))
            )
          )
      )
  );
  const quizScore = finalQuizResult?.filter(Boolean);
  const quizSum = quizScore?.reduce(
    (accumulator, item) => accumulator + item.score,
    0
  );

  return {
    assignment: finalResult?.filter(Boolean),
    quiz: quizScore,
    skillCount: parseInt(quizSum / quizScore?.length),
    KahootResult: finalKahootResult?.filter(Boolean),
  };
};

export function getScoreAndPathForQuest(cohortsData, questName, type) {
  console.log(questName, "02 Linux Fundmentals");
  const data = cohortsData
    ?.flatMap(
      (cohort) =>
        cohort.course
          ?.filter((course) => course.course_name === questName)
          .flatMap((course) =>
            course.student_data?.flatMap((student) =>
              student.lessons?.flatMap((lesson) =>
                lesson.room?.flatMap((room) =>
                  type
                    ? room.quiz?.map((detail) => ({
                        score: detail.quiz_score,
                        name: detail.quiz_name,
                      }))
                    : room.assignment_submitted_detail?.map((detail) => ({
                        name: detail?.path,
                        score: detail?.score,
                      }))
                )
              )
            )
          ) || []
    )
    .filter(Boolean); // Remove undefined/null values
  console.log(data);
  return data;
}

function getMatchingCourse(phaseList, enteredCourseName) {
  const result = phaseList
    ?.map((phase) =>
      phase.macro_certification_list
        ?.map((macroCert) =>
          macroCert.course_list.filter(
            (course) => course.course_name === enteredCourseName
          )
        )
        .flat()
    )
    .flat();

  return result?.length > 0 ? result[0] : null; // Return the first matching course or null
}

export const generateCSV = (inputData) => {
  let {
    studentNodeData,
    canvasData,
    quest_name,
    email,
    kahoot_score_a,
    kahoot_score_b,
  } = inputData;
  const res = getMatchingCourse(studentNodeData?.phase_list, quest_name);
  const canvasRes = canvasData
    .map((item) => ({
      ...item,
      course: item.course.filter((course) => course.course_name === quest_name),
    }))
    .filter((item) => item.course.length > 0);

  const lessonNamesSet = new Set();
  const quizHeadersSet = new Set();
  const assignmentHeaderSet = new Set();
  const canvasLessonNamesSet = new Set();

  canvasRes[0]?.course.forEach((course) => {
    course.student_data.forEach((student) => {
      student.lessons.forEach((lesson) => {
        canvasLessonNamesSet.add(lesson.lesson_name);

        lesson.room.forEach((room) => {
          room.quiz.forEach((quiz) => {
            quizHeadersSet.add(`${quiz.quiz_name} (Score)`);
          });
        });
        lesson.room.forEach((room) => {
          room.assignment_submitted_detail.forEach((assignment) => {
            assignmentHeaderSet.add(`${assignment.path}`);
          });
        });
      });
    });
  });
  res?.lesson_list?.forEach((lesson, index) => {
    index < 5 && lessonNamesSet.add(lesson?.lesson_name);
  });
  const lessonHeaders = Array.from(lessonNamesSet);
  const quizHeaders = Array.from(quizHeadersSet);
  const assignmentHeader = Array.from(assignmentHeaderSet);
  const canvasLessonHeaders = Array.from(canvasLessonNamesSet);

  const headers = [
    "student_email",
    ...lessonHeaders,
    "flex_complete",
    ...canvasLessonHeaders,
    ...quizHeaders,
    ...assignmentHeader,
    "Total Score",
    "kahoot score A",
    "kahoot score B",
  ];
  const csvData = [];
  let row = [];
  row["student_email"] = email;
  let averageQuizScore = 0;
  let averageAssignmentScore = 0;
  canvasRes[0]?.course?.forEach((course) => {
    course.student_data.forEach((student) => {
      let totalQuizScore = 0; // Variable to store total quiz score
      let quizCount = 0; // Variable to count the number of quizzes
      let totalAssignmentScore = 0; // Variable to store total quiz score
      let assignmentCount = 0; // Variable to count the number of quizzes

      // Add lesson progress values
      canvasLessonHeaders.forEach((lessonName, index) => {
        const lesson = student.lessons.find(
          (lesson) => lesson.lesson_name === lessonName
        );
        // Add quiz scores for quizzes within the lesson
        if (lesson && lesson.room) {
          lesson.room.forEach((room) => {
            room.quiz.forEach((quiz) => {
              row[`${quiz.quiz_name} (Score)`] = quiz.quiz_score ?? "N/A";
              if (quiz.quiz_score) {
                totalQuizScore += quiz.quiz_score;
                quizCount++;
              }
            });
          });
        }
        if (lesson && lesson.room) {
          lesson.room.forEach((room) => {
            room?.assignment_submitted_detail?.forEach((assignment) => {
              row[`${assignment?.path}`] = assignment?.score ?? "N/A";
              if (assignment?.score) {
                totalAssignmentScore += assignment?.score;
                assignmentCount++;
              }
            });
          });
        }
      });
      averageQuizScore =
        quizCount > 0
          ? ((parseFloat(totalQuizScore) +
              parseFloat(kahoot_score_a) +
              parseFloat(kahoot_score_b)) /
              3) *
            0.25
          : 0;
      averageAssignmentScore =
        assignmentCount > 0
          ? (totalAssignmentScore / assignmentCount).toFixed(2) * 0.25
          : "N/A";

      row["Total Quiz Score"] = averageQuizScore;
      row["Total Assignment Score"] = averageAssignmentScore;
    });
  });

  // display stage course progress
  lessonHeaders?.forEach((lessonName, index) => {
    console.log("lessonName", lessonName);
    const lesson = res?.lesson_list?.find((lesson) => {
      if (lesson.lesson_name === lessonName) {
        return lesson;
      }
    });
    if (index < 5) {
      // Only consider the first 5 lessons

      row[lessonName] = lesson?.progress_lesson?.progress_actual_cnt
        ? lesson?.progress_lesson?.progress_actual_cnt
        : "0";
    }
  });
  const flex_complete = res?.course_progress?.flex_complete ? "100" : "50";
  row["flex_complete"] = flex_complete;
  const averageQuestScore = res?.course_progress
    ? (res?.course_progress?.progress_actual_cnt / 5).toFixed(2) * 0.4
    : "0";
  row["Total Quest Score"] = averageQuestScore;
  row["Total Score"] =
    parseFloat(averageQuestScore) +
    parseFloat(averageQuizScore) +
    parseFloat(averageAssignmentScore) +
    parseFloat(flex_complete) * 0.1;

  row["kahoot score A"] = kahoot_score_a;
  row["kahoot score B"] = kahoot_score_b;
  csvData.push(row);
  const csvContent = [
    headers.join(","),
    ...csvData.map((row) =>
      headers.map((header) => `"${row[header] || ""}"`).join(",")
    ),
  ].join("\n");

  // // Create a blob and download link
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "lessons_progress.csv";
  document.body.appendChild(link); // Required for Firefox
  link.click();
  document.body.removeChild(link); // Clean up
  URL.revokeObjectURL(url);
};