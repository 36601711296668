import React, { useState } from "react";
import {
  Progress,
  Module,
  Total_Points_Gained_Core,
  Total_Points_Gained_Flex,
} from "resources/constants/strings.js";
import { Block } from "baseui/block";
import { StatefulTooltip } from "baseui/tooltip";
import { useStyletron } from "baseui";
import ProgressBar from "components/progress";
import MicroBadge from "../../TrackSummary/components/badges/microBadge";
import BadgeModal from "../../TrackSummary/components/badges/badgeModal";
import InfoIcon from "assets/images/info-icon.png";
import KAHOOT from "assets/images/wordpress/kahoot.svg";
import SKILL from "assets/images/wordpress/skill.svg";
import Quest_PROGRESS from "assets/images/wordpress/progress.svg";
import ASSIGNMENT from "assets/images/wordpress/assignment.png";
import CAPSTONE from "assets/images/wordpress/capstone.svg";
import { fetchJSData } from "services/requests/jsData";
import courseName from "services/requests/course_name"
import fetchKahootRoomScoreBoard, {
  fetchKahootScore,
} from "services/requests/kahootApi";
import fetchKahoot from "services/requests/kahoot_game_api";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { Skeleton } from "baseui/skeleton";
import {
  fetchCanvasStudentData,
  fetchCohortStudentData,
} from "services/requests/cohort_student_data";
import kahoot from "../../AdminDashboard/components/kahoot.json";
import studentList from "services/requests/student_api";
import FormData from "form-data";
import { getPathAndScore, getScoreAndPathForQuest } from "utils/app-utils";
import { fetchWpScore } from "services/requests/kahootApi";
import { useTrackedState } from "store/store";

const BLOCK_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  border: `1px solid ${theme.RAColors?.gray200}`,

  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
  },
});

const TOTAL_POINTS = (theme) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  border: `1px solid ${theme.RAColors?.gray200}`,
  [theme.mediaQuery.medium]: {
    padding: "24px",
  },
  [theme.mediaQuery.small]: {
    width: "100%",
    padding: "16px",
    display: "block",
  },
  [theme.mediaQuery.large]: {
    padding: "16px",
    width: "auto",
    display: "inherit",
  },
});

const TITLE_STYLE = (theme) => ({
  fontFamily: "Manrope-Bold",
  fontSize: "24px",
  lineHeight: "33px",
  marginBottom: "20px",
  [theme.mediaQuery.small]: {
    fontSize: "18px",
    display: "inline",
  },
  [theme.mediaQuery.large]: {
    fontSize: "24px",
    display: "inherit",
  },
});
const TITLE_WHOLE_SECTION = (theme) => ({
  [theme.mediaQuery.small]: {
    marginBottom: "20px",
  },
});

const ITEM_TITLE_STYLE = (theme) => ({
  fontFamily: "Manrope-Semibold",
  fontSize: "14px",
  lineHeight: "19px",
  marginBottom: "4px",
  color: theme?.RAColors?.gray800,
});

const ITEM_CONTENT_STYLE = (theme) => ({
  fontFamily: "Epilogue-Medium",
  fontSize: "28px",
  lineHeight: "40px",
  color: theme?.RAColors?.gray800,
});

const TOTAL_POINTS_GAINED_BLOCK = (theme) => ({
  [theme.mediaQuery.small]: {
    margin: "0px 70px 0px 70px",
  },
  [theme.mediaQuery.medium]: {
    margin: "0px 0px 0px 0px",
  },
  [theme.mediaQuery.large]: {
    margin: "0px 0px 0px 0px",
  },
});
const AVATAR_STYLE = {};
const AXLP_DATA_SECTION = { display: "inline-flex", width: "20%", gap: "20px" };
const AXLP_DATA_SUB_SECTION = { display: "block" };
const DATA_NAME = {
  display: "block",
  "font-family": "Manrope-Medium",
  paddingBottom: "8px",
};
const DATA_VALS = { "font-family": "Manrope-Medium" };

const Summary = ({
  assignments = {},
  email = null,
  axlpVal = null,
  track = {},
  questData = [],
}) => {
  const [css, theme] = useStyletron();
  const [activeBadge, setActiveBadge] = useState(null);
  const [assignment_detail, setAssignment_detail] = useState();
  const [skill, setSkill] = useState(null);
  const [quest, setQuest] = useState(null);
  const [assignment, setAssignment] = useState(null);
  const [axlpdata, setAXLPData] = useState(null);
  const [asgData, setAsgData] = useState(null);
  const [capstone, setCapstone] = useState(null);
  const [popup, setPopup] = useState(false);
  const [word, setWord] = useState(null);
  const [flexScore, setFlexScore] = useState(null);
  const [kahootScore, setKahootScore] = useState([]);
  const [questScore, setQuestScore] = useState(null);
  const [scoreDetails, setScoreDetails] = useState({
    quiz: [],
    assignment: [],
  });
  React.useEffect(() => {
    // KahgootData();
    CourseNameMatch(assignments?.course_name);
    flexCal();
    getKahootScore();
    // fetch skill check and assignment score from canvas
    getCanvasScore();
  }, []);
  const getCanvasScore = () => {
    const email = localStorage.getItem("email");
    fetchCanvasStudentData(email, "LEARNER").then(function (response) {
      if (response.status === 200) {
        console.log(
          getScoreAndPathForQuest(
            response?.data[0]?.["cohorts_data"],
            assignments?.course_name,
            true
          )
        );

        setScoreDetails({
          ...scoreDetails,
          quiz: getScoreAndPathForQuest(
            response?.data[0]?.["cohorts_data"],
            assignments?.course_name,
            true
          ),
          assignment: getScoreAndPathForQuest(
            response?.data[0]?.["cohorts_data"],
            assignments?.course_name,
            false
          ),
        });
      } else {
      }
    });
  };
  let axlpdataVal, Data, kScore, fScore, aVal, skVal;

  //   const assgn_submit =  [

  //     {
  //       "path": "Q02-S02-R03",
  //       "score": "60",
  //       "submitted_at": "2023-10-23 16:57:08",
  //       "outcome": "FAIL"
  //   },
  //   {
  //     "path": "Q02-S05-R04",
  // "score": "0",
  // "submitted_at": "0000-00-00 00:00:00",
  // "outcome": "FAIL"
  //   },
  // ]

  // const KahgootData = () => {
  //   fetchKahootRoomScoreBoard()
  //     .then(function (response) {
  //       if (response.status === 200) {
  //         fetchKahoot(response.data.access_token).then(function (resp) {
  //           //console.log("kahoot data",resp)
  //         });
  //         response.data.map((obj, ind) => {
  //           if (obj?.coursename === assignments?.course_name) {
  //             obj?.userscores.find((element) => {
  //               if (element.emailid === email) {
  //                 setKahootScore(element?.score);
  //                 kScore = element?.score;
  //               }
  //             });
  //           }
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const getKahootScore = async (assignmentData) => {
  const getKahootScore = async () => {
    const formData = {
      // learnerEmail: email,
      user_email: email,
    };

    // await fetchKahootScore(formData)
    //   .then(function (response) {
    //     if (response.status === 200) {
    // if (typeof response?.data === "object") {
    //   console.log(typeof response?.data);
    //   const scores = Object.entries(response?.data).map(
    //     ([name, score]) => {
    //       return { name, score };
    //     }
    //   );
    const score = JSON.parse(localStorage.getItem("canvas"))?.score;
    setKahootScore(score);
    // } else {
    //   console.log("error", response);
    // }
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  };

  const CourseNameMatch = (name) => {
    // fetchWpScore().then(function (response) {
    let assignment_data = getPathAndScore(
      assignments?.course_name,
      JSON.parse(localStorage.getItem("wpData")),
      // response?.data[0]?.cohorts_data,
      track
    );

    setAssignment_detail(assignment_data);
    setKahootScore(assignment_data?.quiz);
    // });
    const encodeName = encodeURIComponent(name);
    courseName(encodeName)
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          axlpData(response.data);
        } else {
          setWord("1");
        }
      })
      .catch((err) => {
        setWord("1");
        console.log(err);
      });
  };

  function findHighestValue(arr) {
    //let highestValue = -Infinity; // Initialize with a very low value
    let high = 0;
    let frachigh = 0;
    var highestIntValue = 0,
      highestFractionValue = 0;
    var hval,
      harrval = 0;
    let finalval;
    for (let i = 0; i < arr.length; i++) {
      const fraction = arr[i];
      const [numerator, denominator] = fraction.split("/"); // Split the fraction string
      // Convert the parts to numbers
      const numeratorValue = parseFloat(numerator);
      const denominatorValue = parseFloat(denominator);
      if (denominatorValue !== 0) {
        // Calculate the value of the fraction
        const fractionValue = numeratorValue / denominatorValue;
        if (fractionValue === 1 && numeratorValue > high) {
          high = numeratorValue;
          highestIntValue = arr[i];
        } else if (fractionValue > frachigh) {
          frachigh = fractionValue;
          highestFractionValue = arr[i];
        } else {
          hval = numeratorValue;
          harrval = arr[i];
        }
      }
    }
    if (high > 0) {
      finalval = highestIntValue;
    } else if (frachigh > 0) {
      finalval = highestFractionValue;
    } else {
      finalval = harrval;
    }
    return finalval;
  }

  const flexCal = () => {
    var arr = [];
    assignments?.lesson_list?.map((obj) => {
      if (obj.lesson_name.includes("Flex")) {
        obj?.assignment_list.map((ele) => {
          if (ele.assignment_type === "Exercise__Independent_Optional") {
            arr.push(
              parseInt(ele.assignment_progress.progress_actual_cnt) +
                "/" +
                parseInt(ele.assignment_progress.progress_total_cnt)
            );
            //console.log("fraction vals of flex",arr)
            // const fractions = ['2/4', '2/3', '1/1', '3/5'];
            const highest = findHighestValue(arr);
            let hightvals = highest == 0 ? "0/0" : highest;
            const [numerator, denominator] = hightvals?.split("/"); // Split the fraction string
            const numeratorValue = parseFloat(numerator);
            const denominatorValue = parseFloat(denominator);
            const fractionValue =
              denominatorValue == 0
                ? 0
                : (numeratorValue / denominatorValue) * 100;
            setFlexScore(fractionValue.toFixed(2));
            fScore = fractionValue;
          }
        });
      }
    });
  };

  const axlpData = async (courseId) => {
    const learnerEmail = email;

    // fetchCohortStudentData(learnerEmail, "LEARNER")
    fetchCanvasStudentData(learnerEmail, "LEARNER")
      .then(function (response) {
        if (response.status === 200) {
          console.log("Hello");
          // let assignment_data = getPathAndScore(
          //   assignment?.course_name,
          //   // localStorage.getItem("canvas")
          //   response?.data[0]?.cohorts_data
          // );
          // console.log(assignment_data);
          // setAssignment_detail(assignment_data);
          // setKahootScore(assignment_data?.quiz);
          let count = 0,
            qqz,
            total = 0;
          response.data.map((obj, ind) => {
            if (obj?.cohorts_data !== null) {
              obj?.cohorts_data?.map((ele) => {
                ele?.course.map((data, index) => {
                  questScoreCal();
                  if (data?.course_id === parseInt(courseId)) {
                    let asgn_details =
                      data?.student_data[0]?.assignment_submitted_detail;
                    axlpdataVal = data?.student_data[0]?.lessons;
                    var Data = JSON.parse(
                      localStorage.getItem("rolesPermission")
                    );

                    const filtered_quest = asgn_details?.filter(function (el) {
                      if (
                        el?.path.substr(1, 2) ===
                        assignments?.course_name.substr(0, 2)
                      ) {
                        return el;
                      }
                    });
                    questData(filtered_quest);
                    axlpVal(axlpdataVal);
                    setAXLPData(data?.student_data[0]?.lessons);
                    data?.student_data[0]?.lessons?.map((les) => {
                      les?.room?.map((rm) => {
                        if (rm?.quiz?.length > 0) {
                          rm?.quiz?.map((qz) => {
                            setPopup(true);
                            count = count + 1;
                            qqz = qz?.quiz_score === 0 ? "0%" : qz?.quiz_score;
                            total = total + parseInt(qqz.split("%")[0]);
                          });
                        }
                      });
                    });
                    let skill = (total / count).toFixed(2);
                    let vals = filtered_quest; //data?.student_data[0]?.assignment_submitted_detail  //assgn_submit //
                    let vcount = 0;
                    vals.map((obj) => (vcount = vcount + parseInt(obj?.score)));
                    setAsgData(filtered_quest); //data?.student_data[0]?.assignment_submitted_detail //assgn_submit //
                    Data = vals;
                    let per_assgn =
                      vcount === 0
                        ? "Pending"
                        : (vcount / vals.length).toFixed(2) < 100
                        ? "Pending"
                        : (vcount / vals.length).toFixed(2);
                    setQuest(data?.student_data[0]?.course_progress);
                    setAssignment(per_assgn);
                    aVal = per_assgn;
                    setSkill(skill);
                    skVal = skill;
                    questScoreCal();
                    getKahootScore(axlpdataVal);
                  }
                });
              });
            }
          });
          setWord("1");
        }
      })
      .catch((err) => {
        // console.log(err);
        setWord("1");
      });
  };

  const questScoreCal = () => {
    //const vals = ((kahootScore*40/100) + (skill*25/100) + (assignment*25/100) + (track?.new_grade*10/100))
    const lxp = 0;
    const thm_xlp =
      ((assignments?.course_progress?.progress_actual + lxp) / 2) * 0.4;
    const asg =
      assignment === null || assignment === undefined ? 0 : assignment;
    const assigVal = asg * 0.25;
    const sklvals = skill === null || skill === undefined ? 0 : skill;
    const kVal = kScore === null || kScore === undefined ? 0 : kScore;
    const skillVal = ((kVal + sklvals) / 2) * 0.25;
    const fvals = fScore === null || fScore === undefined ? 0 : fScore;
    const flexVals = fvals * 0.1;
    const vals =
      Math.round((thm_xlp + assigVal + skillVal + flexVals) * 100) / 100;
    //console.log("quest score",vals)
    setQuestScore(vals + "%");
  };

  axlpdataVal = axlpdata;
  Data = asgData;
  let assignment_data_detail = getPathAndScore(
    assignments?.course_name,
    // localStorage.getItem("canvas")
    JSON.parse(localStorage.getItem("canvas"))?.studentData[0]?.cohorts_data,
    track
  );

  const assignment_data = assignment_detail || assignment_data_detail;

  return (
    <Block
      className={css(BLOCK_STYLE(theme))}
      marginBottom={["16px", "16px", "20px"]}
    >
      <Block className={css(TITLE_WHOLE_SECTION(theme))} display="inline-block">
        <Block
          as="h3"
          className={css(TITLE_STYLE(theme))}
          marginRight="5px"
          data-testid="assignment_name"
        >
          {"Quests"} : {assignments?.course_name}
        </Block>
        <StatefulTooltip
          accessibilityType={"tooltip"}
          content=""
          showArrow
          placement="top"
        >
          <Block
            data-testid="info-image"
            as="img"
            src={InfoIcon}
            alt="info Icon"
          />
        </StatefulTooltip>
      </Block>

      <Block display="flex" flexWrap="wrap" gridGap="12px" alignItems="center">
        <Block
          paddingLeft="10px"
          paddingRight="10px"
          data-testid="lesson-image"
        >
          <MicroBadge
            width="62px"
            name={assignments?.course_image}
            isFaded={(assignments?.course_progress?.progress_actual || 0) < 100}
          />
        </Block>
        <Block className={css({ ...BLOCK_STYLE(theme), padding: "16px" })}>
          <Block
            className={css(ITEM_TITLE_STYLE(theme))}
            data-testid="progress_text"
          >
            {"Quests" + " " + Progress}
          </Block>
          <Block display="flex" gridGap="16px" alignItems="center">
            <Block
              className={css(ITEM_CONTENT_STYLE(theme))}
              data-testid="progress_trackbar"
            >
              {/* {assignments?.course_name?.substring(0, 2) === "12" || assignments?.course_name?.substring(0, 2) === "15" ? 
          "100" : */}
              {assignments?.course_progress?.progress_actual || 0}%
            </Block>
            <Block width="100px">
              {/* {assignments?.course_name?.substring(0, 2) === "12" || assignments?.course_name?.substring(0, 2) === "15" ? */}
              {/* <ProgressBar
             value={100}
             targetValue={100}
           />
           : */}
              <ProgressBar
                value={assignments?.course_progress?.progress_actual || 0}
                targetValue={assignments?.course_progress?.progress_expected}
              />
              {/* } */}
            </Block>
          </Block>
        </Block>
        {/* <Block
          display="flex"
          flexDirection="row"
          gridGap="20px"
          className={css(TOTAL_POINTS(theme))}
        >
          <Block className={css(TOTAL_POINTS_GAINED_BLOCK(theme))}>
            <Block
              data-testid="total_points_core"
              className={css(ITEM_TITLE_STYLE(theme))}
            >
              {Total_Points_Gained_Core}
            </Block>
            <Block className={css(ITEM_CONTENT_STYLE(theme))}>
              {assignments?.course_skill_points_actual}
              {"/"}
              {assignments?.course_skill_points_total}
            </Block>
          </Block>

          <Block className={css(TOTAL_POINTS_GAINED_BLOCK(theme))}>
            <Block
              data-testid="total_points_flex"
              className={css(ITEM_TITLE_STYLE(theme))}
            >
              {Total_Points_Gained_Flex}
            </Block>
            <Block
              className={css(ITEM_CONTENT_STYLE(theme))}
              data-testid="skill_point_actual"
            >
              {assignments?.course_flex_skill_points_actual}
            </Block>
          </Block>
        </Block>
        <Block
          display="flex"
          flexDirection="row"
          gridGap="20px"
          className={css(TOTAL_POINTS(theme))}
        >
          <Block className={css(TOTAL_POINTS_GAINED_BLOCK(theme))}>
            <Block
              data-testid="total_quest_score"
              className={css(ITEM_TITLE_STYLE(theme))}
            >
              {"Quest Score"}
            </Block>
            <Block
              style={{ fontSize: "16px" }}
              className={css(ITEM_CONTENT_STYLE(theme))}
            >
              {questScore}
            </Block>
          </Block>
        </Block> */}
        {word === "1" ? (
          <Block style={{ width: "100%", marginTop: "30px" }}>
            {/* <Block className={css(AXLP_DATA_SECTION)}>
          <StatefulPopover
              content={() => (
                <div
                  data-testid="table-flex-chart-popover"
                  className={css({
                    width: "260px",
                    // height:"300px",
                    backgroundColor: "white",
                    float: "left",
                    padding: "20px",
                  })}
                >
                  {"Coming Soon"}
           
                </div> 
              )}
              triggerType={TRIGGER_TYPE.hover}
              returnFocus
              autoFocus
            >
              <div
                data-testid="table-chart-flex"
                className={css({
                  cursor: "pointer",
                })}
              >
            <Block
              as="img"
              src={KAHOOT}
              alt="User Avatar"
              className={css(AVATAR_STYLE)}
            />
            </div>
            </StatefulPopover>

            <Block className={css(AXLP_DATA_SUB_SECTION)}>
              <span className={css(DATA_NAME)}>{"Kahoots!"}</span>
              <span className={css(DATA_VALS)}>{"coming soon"}</span>
            </Block>
          </Block> */}

            <Block className={css(AXLP_DATA_SECTION)}>
              <StatefulPopover
                content={() => (
                  <div
                    data-testid="table-flex-chart-popover"
                    className={css({
                      width: "300px",
                      backgroundColor: "white",
                      float: "left",
                      padding: "20px",
                    })}
                  >
                    {/* {axlpdataVal?.map((les, ind) =>
                      les?.room?.map((ele) =>
                        ele?.quiz?.length > 0
                          ? ele?.quiz?.map((obj) => (
                              <Block
                                style={{
                                  minHeight: "34px",
                                  display: "inline-flex",
                                }}
                              >
                                <Block style={{ padding: "10px 0px 0px 10px" }}>
                                  {obj?.quiz_name}
                                  {":"}
                                </Block>
                                <Block style={{ padding: "10px 0px 0px 10px" }}>
                                  {obj?.quiz_score % 1 === 0
                                    ? parseInt(obj?.quiz_score)
                                    : parseInt(obj?.quiz_score).toFixed(2)}
                                </Block>
                                <Block>
                                  <br />
                                </Block>
                              </Block>
                            ))
                          : null
                      )
                    )} */}
                    <Block className={css(AXLP_DATA_SUB_SECTION)}>
                      <div>
                        <span
                          // className={css(DATA_NAME)}
                          style={{
                            display: "inline",
                            paddingLeft: "0.6rem",
                          }}
                        >
                          {/* {"Skill Checks Average - "}
                          {assignment_data
                            ? isNaN(assignment_data?.skillCount)
                              ? 0
                              : assignment_data?.skillCount
                            : isNaN(assignment_data_detail?.skillCount)
                            ? 0
                            : assignment_data_detail?.skillCount
                            ? 0
                            : assignment_data_detail} */}
                          {/* % */}
                        </span>
                        {/* <div
                          className={css(DATA_NAME)}
                          style={{
                            // display: "inline",
                            paddingLeft: "0.6rem",
                            paddingRight: "0.3rem",
                          }}
                        >
                          {"Kahoots!"}
                        </div> */}
                        {/* {assignments?.kahoot_score?.map((score) => ( */}
                        {/* {kahootScore?.map((score) => (
                          <Block
                            style={{
                              minHeight: "34px",
                              display: "inline-flex",
                            }}
                          >
                           
                            {":"}
                            <span style={{ paddingLeft: "0.3rem" }}>
                              {score?.score}%
                            </span>
                          </Block>
                        ))} */}
                      </div>
                    </Block>
                    {track?.gradeSet === "skill" ||
                    scoreDetails?.quiz?.length > 0 ? (
                      <Block className={css(AXLP_DATA_SUB_SECTION)}>
                        {/* <span
                          className={css(DATA_NAME)}
                          style={{ display: "inline-block" }}
                        >
                          <span
                            style={{ padding: "0.6rem", fontSize: "1.5rem" }}
                          >
                            {"Grade"}
                          </span>
                          {assignment_data?.KahootResult?.map((result) => {
                            <Block>
                              {result?.path}
                              {":"}
                              {result?.score % 1 === 0
                                ? parseInt(result?.score)
                                : result?.score}
                            </Block>;
                          })}
                        </span> */}
                        <span
                          className={css(DATA_VALS)}
                          style={{ display: "inline" }}
                        >
                          {/* {track?.new_grade ? track?.new_grade + "%" : "-"} */}
                          {/* {assignment_data?.KahootResult?.map((result) => (
                            <Block style={{ padding: "10px 0px 0px 10px" }}>
                              {"Kahoots!"}
                              {result?.name}
                              {":"}
                              {result?.score % 1 === 0
                                ? parseInt(result?.score)
                                : result?.score}
                            </Block>
                          ))} */}
                          {scoreDetails?.quiz?.map((result) => (
                            <Block style={{ padding: "10px 0px 0px 10px" }}>
                              {result?.name}
                              {":"}
                              {result?.score % 1 === 0
                                ? parseInt(result?.score)
                                : result?.score}
                            </Block>
                          ))}
                        </span>
                      </Block>
                    ) : null}
                  </div> //: null
                )}
                triggerType={TRIGGER_TYPE.hover}
                returnFocus
                autoFocus
              >
                <div
                  data-testid="table-chart-flex"
                  className={css({
                    cursor: "pointer",
                  })}
                >
                  <Block
                    as="img"
                    src={SKILL}
                    alt="User Avatar"
                    className={css(AVATAR_STYLE)}
                  />
                </div>
              </StatefulPopover>

              <Block className={css(AXLP_DATA_SUB_SECTION)}>
                <span className={css(DATA_NAME)}>{"Skills Checks"}</span>
                <span className={css(DATA_VALS)}>
                  {skill > 0 ? skill : 0}
                  {"%"}
                </span>
              </Block>
            </Block>

            <Block className={css(AXLP_DATA_SECTION)}>
              <StatefulPopover
                content={() => (
                  <div
                    data-testid="table-flex-chart-popover"
                    className={css({
                      width: "260px",
                      // height:"300px",
                      backgroundColor: "white",
                      float: "left",
                      padding: "20px",
                    })}
                  >
                    {/* {axlpdataVal?.map((les, ind) => ( */}
                    <Block style={{ minHeight: "34px", display: "flex" }}>
                      {/* <Block style={{ padding: "10px 0px 0px 10px" }}> */}
                      {/* {les?.lesson_name} */}
                      {/* {":"} */}
                      {/* </Block> */}
                      <Block style={{ padding: "10px 0px 0px 0px" }}>
                        {/* {les?.lesson_name.includes("Flex") && flexScore !== 0
                            ? flexScore
                            : les?.lesson_progress.toFixed(2)} */}
                        {assignments?.course_progress?.flex_complete
                          ? "100%"
                          : "0%"}
                      </Block>

                      <Block>
                        <br />
                      </Block>
                    </Block>
                    <Block style={{ fontSize: "0.8rem" }}>
                      Note: you have to achieve at least 50% in a flex room to
                      get credit
                    </Block>
                    {/* ))} */}
                  </div>
                )}
                triggerType={TRIGGER_TYPE.hover}
                returnFocus
                autoFocus
              >
                <div
                  data-testid="table-chart-flex"
                  className={css({
                    cursor: "pointer",
                  })}
                >
                  <Block
                    as="img"
                    src={Quest_PROGRESS}
                    alt="User Avatar"
                    className={css(AVATAR_STYLE)}
                  />
                </div>
              </StatefulPopover>
              <Block className={css(AXLP_DATA_SUB_SECTION)}>
                <span className={css(DATA_NAME)}>{"Flex Score"}</span>
                <span className={css(DATA_VALS)}>
                  {/* {flexScore ? flexScore + "%" : "0%"} */}
                  {assignments?.course_progress?.flex_complete ? "100%" : "0%"}
                </span>
              </Block>
            </Block>

            <Block className={css(AXLP_DATA_SECTION)}>
              <StatefulPopover
                content={() => (
                  <Block>
                    {/* {assignment > 0 || Data?.length > 0 ? ( */}
                    {assignment > 0 ||
                    assignment_data?.assignment?.length > 0 ? (
                      <div
                        data-testid="table-flex-chart-popover"
                        className={css({
                          width: "300px",
                          // height:"300px",
                          backgroundColor: "white",
                          float: "left",
                          padding: "20px",
                        })}
                      >
                        <Block>
                          {/* {keyValue(list)} */}
                          {assignment_data?.assignment?.map((obj) => (
                            <Block
                              style={
                                obj?.outcome === "GRADE-PENDING"
                                  ? { color: "red" }
                                  : { color: "black" }
                              }
                            >
                              {obj?.path} : {obj?.score}
                              {/* {obj?.score == "6" ? "" : obj?.score + "%"}{" "}
                              {obj?.score == "6"
                                ? "Pending"
                                : obj?.score == "0" &&
                                  obj?.submitted_at == "0000-00-00 00:00:00"
                                ? "Not Submitted"
                                : "Submitted"} */}
                            </Block>
                          ))}
                        </Block>
                      </div>
                    ) : null}
                    {track?.gradeSet === "assignment" ? (
                      <Block className={css(AXLP_DATA_SUB_SECTION)}>
                        <span
                          className={css(DATA_NAME)}
                          style={{ display: "inline" }}
                        >
                          {"Grade : "}
                        </span>
                        <span
                          className={css(DATA_VALS)}
                          style={{ display: "inline" }}
                        >
                          {track?.new_grade ? track?.new_grade + "%" : "-"}
                        </span>
                      </Block>
                    ) : null}
                  </Block>
                )}
                triggerType={TRIGGER_TYPE.hover}
                returnFocus
                autoFocus
              >
                <div
                  data-testid="table-chart-flex"
                  className={css({
                    cursor: "pointer",
                  })}
                >
                  <Block
                    as="img"
                    src={ASSIGNMENT}
                    alt="User Avatar"
                    className={css(AVATAR_STYLE)}
                  />
                </div>
              </StatefulPopover>
              <Block className={css(AXLP_DATA_SUB_SECTION)}>
                <span className={css(DATA_NAME)}>{"Assignments"}</span>
                <span className={css(DATA_VALS)}>
                  {/* {assignment > 0 ? assignment : 0}
                  {"%"} */}
                  {scoreDetails?.assignment?.map((obj) => (
                    <Block>
                      {obj?.path} : {obj?.score}
                    </Block>
                  ))}
                </span>
              </Block>
            </Block>

            {/* <Block className={css(AXLP_DATA_SECTION)}>
              <StatefulPopover
                content={() => (
                  <div
                    data-testid="table-flex-chart-popover"
                    className={css({
                      width: "260px",
                      backgroundColor: "white",
                      float: "left",
                      padding: "20px",
                    })}
                  >
                    {"Coming Soon"}
                  </div>
                )}
                triggerType={TRIGGER_TYPE.hover}
                returnFocus
                autoFocus
              >
                <div
                  data-testid="table-chart-flex"
                  className={css({
                    cursor: "pointer",
                  })}
                >
                  <Block
                    as="img"
                    src={CAPSTONE}
                    alt="User Avatar"
                    className={css(AVATAR_STYLE)}
                  />
                </div>
              </StatefulPopover>
              <Block className={css(AXLP_DATA_SUB_SECTION)}>
                <span className={css(DATA_NAME)}>{"Capstone"}</span>
                <span className={css(DATA_VALS)}>{"coming soon"}</span>
              </Block>
            </Block> */}
          </Block>
        ) : (
          <Skeleton width="100%" height={`50px`} animation />
        )}
      </Block>

      {activeBadge && (
        <BadgeModal badge={activeBadge} close={() => setActiveBadge(null)} />
      )}
    </Block>
  );
};

export default Summary;
